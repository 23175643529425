import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'treatmentName',
    standalone: true
})
export class TreatmentNamePipe implements PipeTransform {
 
  transform(value: unknown, ...args: unknown[]): unknown {
    switch (value) {
      case 'CONTRACEPTIVE':
        return "Anticonceptivo";
      case 'HAIR_LOSS_FEM':
        return "Alopecia femenina";
      case 'HAIR_LOSS_MA':
        return "Alopecia masculina";
      case 'ERECTILE_DYSFUNCTION':
        return "Disfunción eréctil";
      case 'SKINCARE_ANTI_AGING':
        return "Anti Aging";
      default:
        return value;
    }
  }

}
