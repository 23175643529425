import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'treatmentColor',
    standalone: true
})
export class TreatmentColorPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    switch(value){
      case 'ANTICONCEPTIVE':
        return 'choiz-badget woman-badget';    
      case 'CONTRACEPTIVE':
        return 'choiz-badget woman-badget';    
      case 'HAIR_LOSS_FEM':
        return 'choiz-badget woman-badget';    
      case 'HAIR_LOSS_MA':
        return 'choiz-badget men-badget';    
      case 'ERECTYLE_DYSFUNCTION':
        return 'choiz-badget men-badget';        
      case 'SKINCARE_ANTI_AGING':
        return 'choiz-badget men-badget';        
      default:
        return value;
    }
  }
}
