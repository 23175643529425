import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'badgetsDateTag',
    standalone: true
})
export class BadgetsDateTagPipe implements PipeTransform {

  constructor(){ }

  transform(value: string, ...args: unknown[]): string {
   const today = Date.now();
   const hoy = new Date(today).toLocaleDateString();

   if(value){
    const date_1: any = this.genFecha(value);
    const date_2: any = this.genFecha(hoy);
    var fechaInicio = new Date(date_1).getTime();
    var fechaFin    = new Date(date_2).getTime();

    var diff = fechaFin - fechaInicio;
    const diferencia: number = Math.round(diff/(1000*60*60*24));
 
    switch(true){
        case (diferencia == 7):
          return 'Falta de pago';
        case (diferencia == 8):
          return 'Ultimatum pago';
        case (diferencia == 12):
          return 'WhatsApp cancelacion';
        case (diferencia > 12 && diferencia <= 20):
          return 'En espera';
        default :
        return '';
    }
   } else {
     return null;
   }

  }

  genFecha(value: string): String{
    let dateParts = value.split('/');
    let fecha: string;
    fecha = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    return fecha;
  }

}
