import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'badgetsRecurrent',
    standalone: true
})
export class BadgetsRecurrentPipe implements PipeTransform {

  transform(value: boolean, ...args: unknown[]): string {
    switch (value) {
      case true:
        return "choiz-badget-recurrent gray-badget";
      case false:
        return "choiz-badget-recurrent green-badget";
      default:
        return "choiz-badget-recurrent gray-badget";
    }
    
  }

}
