import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'badgets',
    standalone: true
})
export class BadgetsPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): string {
    switch (value) {
      // Estados
      case 'WITHOUT_CHECK_DOCTOR':
        return "choiz-badget gray-badget";
      case 'CANCEL_BY_DOCTOR':
        return "choiz-badget gray-badget";
      case 'WAITING_FOR_PAYMENT':
        return "choiz-badget ligthBlue-badget";
      case 'NEED_USER':
        return "choiz-badget ligthBlue-badget";
      case 'CANCEL_BY_USER':
        return "choiz-badget gray-badget";
      case 'DELETED':
        return "choiz-badget gray-badget";                   
      case 'PROBLEM_INFO_USER':
        return "choiz-badget woman-badget";
      case 'PACK_OFF':
        return "choiz-badget violet-badget";
      case 'IN_DISTRIBUTION':
        return "choiz-badget ligthBlue-badget";
      case 'DELIVERED':
        return "choiz-badget green-badget";
      //Solicitudes  
      case 'VALIDATE':
        return "choiz-badget orange-badget";
      case 'TELEMEDICINE':
        return "choiz-badget violet-badget";  
      case 'APPROVED':
        return "choiz-badget green-badget";  
      case 'REJECTED':
        return "choiz-badget woman-badget";  
      // Sub Estados
      case 'DIGITAL_CREDENTIAL':
        return "choiz-badget green-badget";          
      case 'INVALID_CREDENTIAL':
        return "choiz-badget blue-badget";
      case 'NOT_REGISTERED':
        return "choiz-badget gray-badget";
      case 'EXCEED_LIMIT_BETWEEN_DELIVERIES':
        return "choiz-badget xl gray-badget";
      case 'EXCEED_ANNUAL_LIMIT':
        return "choiz-badget gray-badget";
      case 'MEDICINE_PARTIAL_COVERED_BY_INSURANCE':
        return "choiz-badget xl gray-badget";
      case 'MEDICINE_NOT_COVERED_BY_INSURANCE':
        return "choiz-badget xl gray-badget";
      case 'MEDICINE_OUT_OF_SOCIAL_SECURITY':
        return "choiz-badget xl gray-badget";
      case 'PARTIAL_SOCIAL_SECURITY':
        return "choiz-badget gray-badget";
      case 'PENDING_PAYMENT':
        return "choiz-badget gray-badget";
      case 'URGENT_PROBLEM':
        return "choiz-badget gray-badget";
      case 'NO_STOCK':
        return "choiz-badget gray-badget";
      case 'NO_AUTHORIZED_ISSURANCE':
        return "choiz-badget gray-badget";
      case 'MEDICAL_CHECK':
        return "choiz-badget gray-badget";
      // Nuevos
      case 'FIRST_TIME':
        return "choiz-badget gray-badget";
      case 'REFILL':
        return "choiz-badget gray-badget";
      case 'INSUFFICIENT_FOUNDS':
        return "choiz-badget gray-badget";
      case 'CARD_ERROR':
        return "choiz-badget gray-badget";
      case 'DELIVERY_PRODUCT':
        return "choiz-badget gray-badget";
      case 'DELIVERY_VACATIONS':
        return "choiz-badget gray-badget";
      case 'DELIVERY_MEDICAL_REVISION':
        return "choiz-badget gray-badget";
      case 'ERROR':
        return "choiz-badget gray-badget";
     


      default:
        return "choiz-badget gray-badget";
    }
    
  }

}
