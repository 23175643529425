import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { xApiKeyMyAccount } from '@core/interceptors/auth.interceptor';
import { environment } from '@environments/environment';
import { Observable, Subject, Subscription, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IDoctor, ITreatments, IPharmacy, IGender, ISex } from '@core/interfaces';
import { AuthService } from '@shared/auth/services/auth.service';


@Injectable({
  providedIn: 'root'
})
export class AuxiliaresService {

  private http = inject(HttpClient);
  private auth = inject(AuthService);

  pharmacies = signal<IPharmacy[] | undefined>(undefined);
  doctors = signal<IDoctor[] | undefined>(undefined);
  treatments = signal<ITreatments[] | undefined>(undefined);
  sexs = signal<ISex[] | undefined>(undefined);
  genders = signal<IGender[] | undefined>(undefined);
  dateValue: any;

  from = signal<any | undefined>('')
  to = signal<any | undefined>('')
  newDate = signal<any | undefined>(undefined)
  
  applyFilter = signal<boolean>(false)
  

  methods: any = [];
  methodId: number = 1;
  brands: any = [];

 
  // Seleccionados
  selectedPharmacy = signal<IPharmacy | undefined>(undefined);
  treatmentSelected = signal<ITreatments | undefined>(undefined);
  dateSelected = signal<any | undefined>(undefined);
  doctorSelected = signal<IDoctor>(undefined);

  private subscriptions: Subscription = new Subscription;
  private unsubscribe$ = new Subject<void>();
 
  // Listeners
  // pharmacieSelectedChange: EventEmitter<any> = new EventEmitter();
  // doctorSelectedChange: EventEmitter<any> = new EventEmitter();
  // treatmentSelectedChange: EventEmitter<any> = new EventEmitter();
  
  endpoint: string = '';

  constructor(){
    this.getAll()
  }

  async getAll(){
    if(this.auth.isAdmin()){
      this.getPharmacies().pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        this.pharmacies.set(res);
      })

      this.getDoctors().pipe(takeUntil(this.unsubscribe$)).subscribe(res => {
        this.doctors.set(res);
      })
    }
  }

  getPharmacies(){
    if(this.auth.isAdmin()){
    this.endpoint = 'pharmacy';
    return this.http.get<IPharmacy[]>(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(
        map((response: any) => {
          this.pharmacies.set(response);
          return response;
        })
      );
    } else {
      return of([])
    }
  }

  getDoctors(){
    if(this.auth.isAdmin()){
    //forms/doctor?treatment_name=${treatment_name}
    this.endpoint = 'forms/doctor';
    return this.http.get<IDoctor[]>(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(
        map((response: any) => {
          this.doctors.set(response)
          return response;
        })
      );
    } else {
      return of([])
    }
  }

  getDoctorsByTreatment(treatment_name){
    if(this.auth.isAdmin()){
    this.endpoint = `forms/doctor?treatment_name=${treatment_name}`; //'forms/doctor';
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(
        map((response: any) => {
          this.doctors.set(response);
          return response;
        })
      );
    } else {
      return of([])
    }
  }

  getTreatments(){
    this.endpoint = 'treatments';
    return this.http.get<ITreatments[]>(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(
        map((response: any) => {
          this.treatments.set(response);
          return response;
        })
      );
    
  }

  getSex(){
    this.endpoint = 'api/birth-sex';
    return this.http.get<ISex[]>(`${environment.apiMyAccountUrl}/${this.endpoint}`, {
      context: xApiKeyMyAccount()
    }).pipe(
        map((response: any) => {
          this.sexs.set(response);
          return response;
        })
      );
  }

  getGender(){
    this.endpoint = 'api/self-perceived-gender';
    return this.http.get<IGender[]>(`${environment.apiMyAccountUrl}/${this.endpoint}`, {
      context: xApiKeyMyAccount()
    }).pipe(
        map((response: any) => {
          this.genders.set(response);
          return response;
        })
      );
  }

  getMethods(idTreatment){
    this.methods = [];
    let endpoint = `medicines/option/medication_type?treatment_id=${idTreatment}`;
    return this.http.get(`${environment.apiUrl}/${endpoint}`)
      .pipe(
        map((response: any) => {
          this.methods = response.filter(methods => methods.label !== 'Quiero una valoración médica' ) 
          return this.methods;
        })
      )
  }

  getMethodsAll(){
    this.methods = [];
    let endpoint = `medicines/option/medication_type`;
    return this.http.get(`${environment.apiUrl}/${endpoint}`)
      .pipe(
        map((response: any) => {
            this.methods = response.filter(methods => methods.label !== 'Quiero una valoración médica' ) 
          return this.methods;
        })
      )
  }

  getBrands(id: number){
    let endpoint = `medicines/option`;
    return this.http.get(`${environment.apiUrl}/${endpoint}/${id}`)
      .pipe(
        map((response: any) => {
          const respuesta = [];
          response.map(r => {
            const { value, label } = r;
            const resp = { id: Number(value), brand: label, formulasToString: '' }
            respuesta.push(resp);
          })
          respuesta.unshift({ id: 0, brand: 'Seleccione una opción', formulasToString: '' })
          this.brands = respuesta;
          return respuesta;
        })
      )
  }

  getBrandsModified(){
    this.endpoint = 'medicines';
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`, {
      params: new HttpParams()
      .set('size', '200')
    }).pipe(
        map((res: any) => {
            const result = res.result.reduce((acc, d) => {
            const value = { value: d.id, label: d.brand };
            acc.push(value);
            return acc;
          }, []);
          return result;
        })
    );
  }

  validatePostalCode(zipCode: string, treatment: string): Observable<any> {
    this.endpoint = 'locations/validations/zipcodes';
    return this.http.post<any>(`${environment.apiUrl}/${this.endpoint}`, { zipCode, treatment });
  }

  getCombos(id: number){
    this.endpoint = `combos/treatments/${id}`; 
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`).pipe(
      map((response: any) => {
        if(id == 4){
          console.log('GET COMBOS', response);
          let formulaString = '';
          response.map(cbo => {
            const cboId = cbo.medicines[0].comboId
            if(cboId == 42){
              formulaString = `Tretinoina 0.025%`
            }
            if(cboId == 43){
              formulaString = `Tretinoína 0.05%`
            }
            if(cboId == 44){
              formulaString = `Tretinoína 0.1%`
            }
            return cbo['formula'] = formulaString
          })
        }
        return response;
      })
    )
  }
  
}
