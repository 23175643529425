import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'tag',
    standalone: true
})
export class TagPipe implements PipeTransform {

  transform(value: any, ...args: unknown[]): unknown {
    if(value){
      return `${this.genString(value)}`;
    } else {
      return null;
    }
  }

  genString(value: any): any {
    let etapa: string = '';
    switch(value){
      case 'IN_TREATMENT' || 3:
        etapa = `En tratamiento`;
        break;
      case 'SIDE_EFFECTS' || 4:
        etapa = `Efectos adversos`;
        break;
      case 'DONT_SEE_RESULT' || 6:
        etapa = `No ve resultados`;
        break;
      case 'CHANGED_TREATMENT' || 5:
        etapa = `Cambio de tratamiento`;
        break;
      case 'CHANGED_FREQUENCY' || 7:
        etapa = `Cambio de frecuencia`;
        break;
      case 'DONT_STARTED' || 1:
        etapa = `No empezó`;
        break;
      case 'CHURN' || 2:
        etapa = `Baja`;
        break;
      case 'VALIDATE':
        etapa = `Validar`;
        break;
      case 'APPROVED':
        etapa = `Aprobadas`;
        break;
      case 'REJECTED':
        etapa = `Rechazadas`;
        break;
      case 'TELEMEDICINE':
        etapa = `Telemedicina`;
        break;
    }
    return etapa;
  }

}
