import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fecha',
    standalone: true
})
export class FechaPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value){
      return `${this.genFecha(value)}`;
    } else {
      return null;
    }
  }

  genFecha(value: string): String{
    let dateParts = value.split(' ');
    let fecha: string;
    fecha = dateParts[0];
    return fecha;
  }

}
