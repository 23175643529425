import { Observable } from 'rxjs';
import { Pipe, PipeTransform, inject } from '@angular/core';
import { OrderStatusService } from '@services/orders-status.service';

@Pipe({
    name: 'EstadosMap',
    standalone: true
})
export class EstadosPipe implements PipeTransform {

 private _orderStatus = inject(OrderStatusService)

  transform(value: string): string | Promise<string> {
    if(this._orderStatus.states() && this._orderStatus.states().length > 0){
      const filtered = this._orderStatus.states().filter(e => e.value == value)[0];
      if(filtered){
        return filtered.label;
      }
      return '';
    }
    return '';
  }

}
