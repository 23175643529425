import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fecha2',
    standalone: true
})
export class Fecha2Pipe implements PipeTransform {

  transform(value: string, type: string = '/', ...args: unknown[]): unknown {
    if(value){
      return `${this.genFecha(value, type)}`;
    } else {
      return null;
    }
  }

  genFecha(value: string, type: string = '/'): String{
    let dateParts = value.split('-');
    let fecha: string;
    fecha = `${dateParts[2]}${type}${dateParts[1]}${type}${dateParts[0]}`;
    return fecha;
  }

}
