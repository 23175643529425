import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { IFollowUp } from '@core/interfaces/followup.interface';
import { TagPipe } from '@core/pipes/tag.pipe';
import { environment } from '@environments/environment';
import { Observable, map, of } from 'rxjs';

interface State {
  follows: any[],
  loading: boolean
}

// size = 10;
// page = 0;
// treatmentId = 2;
// lastName = "";
// clientId = '';
// status = "DONT_STARTED";
// email = '';
// size,treatment_id,email,name,page,client_id,status;
interface Filters {
  size: string;
  treatment_id: string;
  email: string;
  name: string;
  page: string;
  client_id: string;
  status: string;  
}

@Injectable({
  providedIn: 'root'
})
export class FollowupService {

  private http = inject(HttpClient);
  private _tagPipe = inject(TagPipe);

  endpoint = `follow-up`;

  #state = signal<State>({
    loading: true,
    follows: []
  })

  searchItem = signal<string>('');

  public follows = computed(() => this.#state().follows);
  public loading = computed(() => this.#state().loading);

  status: any = [];

  lastEventStatus: string = '';

  categories = signal<any>([]);
  mockCategories = [
    {
        "id": 1,
        "label": "Dolores"
    },
    {
        "id": 2,
        "label": "Sexuales"
    },
    {
        "id": 3,
        "label": "Cardiovasculares"
    },
    {
        "id": 4,
        "label": "Nerviosos"
    },
    {
        "id": 5,
        "label": "Dermatológicos"
    },
    {
        "id": 6,
        "label": "Psicológicos"
    },
    {
        "id": 7,
        "label": "Otros"
    }
]

effectsAll = signal<any>([]);
effects = signal<any>([]);
mockEffects = [
  {
      "id": 6,
      "label": "Disfunción eréctil"
  },
  {
      "id": 7,
      "label": "Disminución de la libido"
  },
  {
      "id": 8,
      "label": "Eyaculación retardada"
  },
  {
      "id": 9,
      "label": "Disminución en el volumen seminal"
  },
  {
      "id": 33,
      "label": "Otro"
  }
]

  filters = signal<Filters | undefined>(
    {
      size: '10',
      treatment_id: '',
      email: '',
      name: '',
      page: '0',
      client_id: '',
      status: '',
    }
  );

  setState(follows: any){
    this.#state.set({
      loading: false,
      follows
    })
  }

  clearFilters(){
    this.filters.set(undefined)
  }
  resetFilters(){
    this.filters.set(
      {
        size: '10',
        treatment_id: '',
        email: '',
        name: '',
        page: '0',
        client_id: '',
        status: '',
      }
    )
  }

  getClientDetail(id: number, treatmentId: number): Observable<IFollowUp>{
    //const treatmentId = 2;
    const endpoint = `follow-up/clients/${id}/treatments/${treatmentId}`;
    return this.http.get(`${environment.apiUrl}/${endpoint}`).pipe(
      map((res: any) => {
        return res
      })
    )
  }

  getLastForm(id: number, treatmentName: string){
    //const treatmentName = 'HAIR_LOSS_MA';
    const endpoint = `follow-up/clients/${id}/treatments/${treatmentName}/forms`;
    return this.http.get(`${environment.apiUrl}/${endpoint}`).pipe(
      map(res => {
        return res
      })
    )
  }

  getTags(){
    const endpoint = `follow-up/status`;
    return this.http.get(`${environment.apiUrl}/${endpoint}`).pipe(
      map((res: any) => {
        res.map(s => {
          return s.label = this._tagPipe.transform(s.status);
        })
        this.status = res;
        return res
      })
    )
  }

  getHistory(clientId: number, treatmentId: number){
    const endpoint = `follow-up/clients/${clientId}/treatments/${treatmentId}/status`;
    return this.http.get(`${environment.apiUrl}/${endpoint}`).pipe(
      map(res => {
        return res
      })
    )
  }

  getList(): Observable<IFollowUp>{
    // size: number, page: number, treatmentId: number, lastName: string, email: string, clientId: string, status: string
    let params = new HttpParams({ fromObject: Object.assign(this.filters()) });
    // const endpoint = `follow-up?size=${size}&treatment_id=${treatmentId}&email=${email}&name=${lastName}&page=${page}&client_id=${clientId}&status=${status}`;
    this.endpoint = `follow-up`;
    return this.http.get<IFollowUp>(`${environment.apiUrl}/${this.endpoint}`, { params }).pipe(
      map((res: IFollowUp) => {
        return res
      })
    )
  }

  putNewState(clientId: any, treatmentId: any, data: any){
    const endpoint = `follow-up/clients/${clientId}/treatments/${treatmentId}/status`;
    const _data = { followUpId: data?.tags, categories: data?.categories ? data?.categories : {}, comment: data?.comment }
    
    return this.http.put(`${environment.apiUrl}/${endpoint}`, _data).pipe(
      map(res => {
        return res
      })
    )
    //return of([])
  }

  getCategories(statusId: string){
    const endpoint = `follow-up/status/${statusId}/categories`;
    return this.http.get(`${environment.apiUrl}/${endpoint}`).pipe(
      map((res: any) => {
        this.categories.set(res)
        return res
      })
    )
    //return of(this.mockCategories)
  }

  getEffects(statusId: string, categoryId: string){
    const endpoint = `follow-up/status/${statusId}/categories/${categoryId}/effects`;
    return this.http.get(`${environment.apiUrl}/${endpoint}`).pipe(
      map((res: any) => {
        this.effects.set(res)
        return res
      })
    )
    //return of(this.mockEffects)
  }

  getEffectsAll(statusId: string){
    const endpoint = `follow-up/status/${statusId}/categories/effects`;
    return this.http.get(`${environment.apiUrl}/${endpoint}`).pipe(
      map((res: any) => {
        this.effectsAll.set(res)
        return res
      })
    )
  }
}
