import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'RequestEstadosMap',
    standalone: true
})
export class RequestEstadosPipe implements PipeTransform {

constructor(){}

  transform(value: string): string | Promise<string> {
   if(value){
    switch(value){
      case 'VALIDATE':
        return 'Validar';
      case 'APPROVED':
        return 'Aprobado';
      case 'REJECTED':
        return 'Rechazado';
      case 'TELEMEDICINE':
        return 'Telemedicina';
      case 'WAITING_FOR_PAYMENT':
        return 'A Cobrar';
      case 'INCOMPLETE':
        return 'Incompleta';
    }
   }
   return '';
  }

}
