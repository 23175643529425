import { HttpClient } from "@angular/common/http";
import { Injectable, signal } from "@angular/core";
import { IOrderStatus, IOrderSubState } from "@core/interfaces";
import { environment } from "@environments/environment";
import { Observable, map } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class OrderStatusService {

    private endpoint = "order-status"
    //public status: IOrderStatus[] = [];
    public states = signal<IOrderStatus[] | undefined>(undefined);
    public substates = signal<IOrderSubState[] | undefined>(undefined);
    public selectedState = signal<IOrderStatus | undefined>(undefined);
    public selectedSubState = signal<IOrderSubState | undefined>(undefined);
    
    constructor(
        private http: HttpClient
    ) { }

    getOrderStates(): Observable<any> {
        return this.http.get<IOrderStatus[]>(`${environment.apiUrl}/${this.endpoint}`).pipe(
            map(estados => {
                this.states.set(estados);
                return estados;
            })
        )
    };
}