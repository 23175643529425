import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { xImageMyAccount } from '@core/interceptors/auth.interceptor';
import { IOrder, IOrderEdit } from '@core/interfaces/orders.interface';
import { BadgetsPipe } from '@core/pipes/badgets.pipe';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';
import { AuxiliaresService } from '@core/services/auxiliares.service';
import { SubEstadosPipe } from '@core/pipes/subestados.pipe';
import { BadgetsRecurrentPipe } from '@core/pipes/badgetsRecurrent.pipe';
import { BadgetsDateTagPipe } from '@core/pipes/badgetsDateTag.pipe';
import { CookieService } from 'ngx-cookie-service';

interface State {
  orders: any[],
  loading: boolean
}

interface Filters {
  status: string;
  sub_status: string;
  recipeStatus: string;
  order_id: string;
  dni: string;
  from: string;
  to: string;
  name: string;
  user_name: string;
  pharmacy_id: string;
  treatment_id: string;
  sort: string;
  page: string;
  size: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  private http = inject(HttpClient);
  private badgetsPipe = inject(BadgetsPipe);
  private badgetsRecurrentPipe = inject(BadgetsRecurrentPipe);
  private badgetsDateTagPipe = inject(BadgetsDateTagPipe);
  private subestadosPipe = inject(SubEstadosPipe);
  private _auxiliares = inject(AuxiliaresService);
  private _cookiesService = inject(CookieService);

  private endpoint: string = 'orders';

  #state = signal<State>({
    loading: true,
    orders: []
  })

  searchItem = signal<string>('');

  filters = signal<Filters | undefined>(
    {
      status: '',
      sub_status: '',
      recipeStatus: '',
      order_id: '',
      dni: '',
      from: '',
      to: '',
      name: '',
      user_name: '',
      pharmacy_id: '',
      treatment_id: '',
      sort: 'ORDER_ID_DESC',
      page: '1',
      size: '10'
    }
  );

  public orders = computed(() => this.#state().orders);
  public loading = computed(() => this.#state().loading);

  setState(orders: any){
    this.#state.set({
      loading: false,
      orders
    })
  }

  clearFilters(){
    this.filters.set(undefined)
  }
  resetFilters(){
    this.filters.set(
      {
        status: '',
        sub_status: '',
        recipeStatus: '',
        order_id: '',
        dni: '',
        from: '',
        to: '',
        name: '',
        user_name: '',
        pharmacy_id: '',
        treatment_id: '',
        sort: 'ORDER_ID_DESC',
        page: '1',
        size: '10'
      }
    )
  }

  getOrders(){
    this.endpoint = 'orders'
    let params = new HttpParams({ fromObject: Object.assign(this.filters()) });
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`, { params }).pipe(
      map((response: any) => {
        response.result.forEach(order => {
          order.client['enteriName'] = `${order.client.name} ${order.client.lastName}`;
          order['directionPostal'] = `${order.street} ${order.streetNumber} ${order.apartment ? order.apartmentNumber : ''}, CP ${order.zipCode ? order.zipCode : ''}`;
          order['badgetClass'] = `${this.badgetsPipe.transform(order['status'])}`;
          order['substate'] ? order['substate'] = `${this.subestadosPipe.transform(order['status'], order['substate'])}` : null;
          order['badgetClassRecurrent'] = `${this.badgetsRecurrentPipe.transform(order['recurrent'])}`;
          order['badgetClassDateTag'] = `${this.badgetsDateTagPipe.transform(order['created'])}`;
          order['badgetDateTag'] = `${this.badgetsDateTagPipe.transform(order['created'])}`;
        })
        return response;
      })
    )
  }

  getOrder(id: number){
    this.endpoint = `orders/${id}`;
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(
        map((response: any) => {
          const resp = response
          resp['badgetClass'] = `${this.badgetsPipe.transform(resp['status'])}`;
          resp['badgetClassDateTag'] = `${this.badgetsDateTagPipe.transform(resp['created'])}`;
          resp['badgetDateTag'] = `${this.badgetsDateTagPipe.transform(resp['created'])}`;
          return resp;
        })
      )
  }

  getAdditionalProducts(){
    this.endpoint = `additional-products`;
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`)
      .pipe(
        map((response: any) => {
          response.map(pa => {
            pa['fullname'] = pa.name +' '+ pa.detail.toLowerCase();
          })
          response.unshift({ fullname: 'Seleccione una opción', value: null })
          return response;
        })
      )
  }

  updateOrderMedicine(orderId: number, orderMedicine: any){
    this.endpoint = `orders/${orderId}/order-medicines`;
    return this.http.put(`${environment.apiUrl}/${this.endpoint}`, orderMedicine).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateOrder(orderEdited: IOrderEdit, selectedOrder: IOrder){
    this.endpoint = `orders`;

    let newOrder: IOrder = {...selectedOrder};
    if(orderEdited.order.medicine){
      newOrder.medicine = orderEdited.order.medicine
    }
    if(orderEdited.order.comboId){
      newOrder.comboId = orderEdited.order.comboId
    }

    if(orderEdited.order.orderMedicines){
      newOrder.orderMedicines = orderEdited.order.orderMedicines
    }
    newOrder.healthInsuranceCoverage = Number(orderEdited.order.healthInsuranceCoverage);
    newOrder.paymentLink = orderEdited.order.paymentLink;
    newOrder.street = orderEdited.shipping.street;
    newOrder.streetNumber = orderEdited.shipping.streetNumber;
    newOrder.zipCode = orderEdited.shipping.zipCode;
    newOrder.apartment = orderEdited.shipping.apartmentNumber != '' ? true : false;
    newOrder.apartmentNumber = orderEdited.shipping.apartmentNumber;
    newOrder.province = orderEdited.shipping.province;
    newOrder.city = orderEdited.shipping.city;
    newOrder.colony = orderEdited.shipping.colony;
    newOrder.additionalInformation = orderEdited.shipping.additionalInformation;
    newOrder.trackingUrl = orderEdited.shipping.trackingUrl;
    newOrder.pharmacy = this._auxiliares.selectedPharmacy() == null ? selectedOrder.pharmacy : this._auxiliares.selectedPharmacy();
    newOrder.doctor = this._auxiliares.doctorSelected() == null ? selectedOrder.doctor : this._auxiliares.doctorSelected();
    delete newOrder['doc'];

    return this.http.put(`${environment.apiUrl}/${this.endpoint}/${newOrder.id}`, newOrder).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  updateCommentOrder(id, order, _comments){
    this.endpoint = `orders`;
    const commentOrder = _comments.commentOrder;
    const commentChoiz = _comments.commentChoiz;

    return this.http.put(`${environment.apiUrl}/${this.endpoint}/${id}`, {...order, commentOrder, commentChoiz }).pipe(
      map((response: any) => {
        return response;
      })
    )
  }

  changeStatus(orderId:number, form: any){
    this.endpoint = `orders`;
    form.commentOrder = '-';
    return this.http.patch(`${environment.apiUrl}/${this.endpoint}/${orderId}/updateStatus`, form).pipe(
     map((data: any) => {
       return data;
      })
    )
  }

  changeStatusRefund(orderId:number, form: any){
    this.endpoint = `orders`;
    return this.http.put(`${environment.apiUrl}/${this.endpoint}/${orderId}/refunds`, form).pipe(
     map((data: any) => {
       return data;
      })
    )
  }

  printLabel(orderId: number){
    this.endpoint = `orders`;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
      responseType: 'blob' as 'json'
    };
    return this.http.get(`${environment.apiUrl}/${this.endpoint}/${orderId}/labels`, options).pipe(
      map((data: any) => {
        return data;
       })
     )
  }

  revalidateLabel(orderId: number){
    this.endpoint = `orders/${orderId}/shipping`;
    const headers = new HttpHeaders({
      'Authorization': 'Bearer ' + this._cookiesService.get('token'),
    });

    return this.http.put(`${environment.apiUrl}/${this.endpoint}`, {}, {headers})
  }

  descargarReceta(id: string) {
    this.endpoint = `orders/${id}/prescription`;
    const httpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    const options = {
      headers: httpHeaders,
      responseType: 'blob' as 'json'
    };

    return this.http.get(`${environment.apiUrl}/${this.endpoint}`, options)
    .pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  regenerarReceta(id: string){
    this.endpoint = `orders/${id}/prescription/import`;
    return this.http.post(`${environment.apiUrl}/${this.endpoint}/${id}/prescription/import`, '')
    .pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  getImageFile(fileName:string){
		const chatEndpoint = environment.apiUrl;
		const chatFile = "resource";

		const headers = new HttpHeaders({
			'file-name': fileName,
			'bucket-folder-name': 'my-account/customer'
		})

		return this.http.get(`${chatEndpoint}/${chatFile}`, {
			headers,
			responseType: 'blob',
			context: xImageMyAccount()
		}).pipe(
			image => { return image }
		)
	}

  getComboName(id){
    this.endpoint = `combos/${id}`;
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`)
    .pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  massiveLabels(data: any){
    this.endpoint = 'orders/labels';
    return this.http.post(`${environment.apiUrl}/${this.endpoint}`, data)
    .pipe(
      map((response: any) => {
        return response;
      })
    );
  }

  massiveRecipes(data: any){
    this.endpoint = 'orders/prescriptions/params';
    return this.http.post(`${environment.apiUrl}/${this.endpoint}`, data)
    .pipe(
      map((response: any) => {
        return response;
      })
    );
  }

}
