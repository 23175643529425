import { Pipe, PipeTransform, inject } from '@angular/core';
import { OrderStatusService } from '@services/orders-status.service';

@Pipe({
    name: 'SubEstadosMap',
    standalone: true
})
export class SubEstadosPipe implements PipeTransform {

  private _orderStatus = inject(OrderStatusService);

  transform(status: string, value: string): string | Promise<string> {
    if(this._orderStatus.states() && this._orderStatus.states().length > 0){
      const filtered = this._orderStatus.states().filter(estado => estado.value == status)[0]['subStates'];
      const substatusFiltered = filtered.filter(subestado => subestado.name == value)[0]
      if(substatusFiltered){
        return substatusFiltered.label;
      }
      return ''
    }
    return '';
  }

}
