import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'edad',
    standalone: true
})
export class EdadPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if(value){
      // return `${this.calculateAge(value)} Años`;
      return `${this.calculateAgeDate(value)} Años`;
    } else {
      return null;
    }
  }

  calculateAge(value: string): number {
    let dateParts = value.split('-');
    let age: number;
    
    if(dateParts.length == 3){
      dateParts.reverse()
      const today: Date = new Date();
      let dateObject = new Date(+dateParts[2], +dateParts[0]+1, +dateParts[1]);
      const birthDate: Date = new Date(dateObject);
      age = today.getFullYear() - birthDate.getFullYear();
      const month: number = today.getMonth() - birthDate.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
    } else {
      dateParts = value.split('/');
      const today: Date = new Date();
      //let dateObject = new Date(+dateParts[2], +dateParts[1] - 1, +dateParts[0]);
      let dateObject = new Date(+dateParts[2], +dateParts[0]+1 , +dateParts[1]);
      const birthDate: Date = new Date(dateObject);
      age = today.getFullYear() - birthDate.getFullYear();
      const month: number = today.getMonth() - birthDate.getMonth();
      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
          age--;
      }
    }

    return age;
  }

  calculateAgeDate(dateString: string){
    let hoy = new Date();
    let fechaNacimiento = new Date(dateString);
    let edad = hoy.getFullYear() - fechaNacimiento.getFullYear();
    let diferenciaMeses = hoy.getMonth() - fechaNacimiento.getMonth();
    if (
      diferenciaMeses < 0 ||
      (diferenciaMeses === 0 && hoy.getDate() < fechaNacimiento.getDate())
    ) {
      edad--;
    }
    return edad;
  }

}
