import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, computed, inject, signal } from '@angular/core';
import { IRequests } from '@core/interfaces/requests-interface';
import { EdadPipe } from '@core/pipes/edad.pipe';
import { FechaPipe } from '@core/pipes/fecha.pipe';
import { RequestEstadosPipe } from '@core/pipes/requestEstados.pipe';
import { TagPipe } from '@core/pipes/tag.pipe';
import { TreatmentColorPipe } from '@core/pipes/treatment-color.pipe';
import { TreatmentNamePipe } from '@core/pipes/treatment-name.pipe';
import { environment } from '@environments/environment';
import { map, Observable } from 'rxjs';

interface State {
  requests: any[],
  loading: boolean
}

interface Filters {
  id: string;
  dni: string;
  status: string;
  page: string;
  size: string;
  user_name: string;
  doctor: string;
}

@Injectable({
  providedIn: 'root'
})
export class RequestsService {

  private http = inject(HttpClient);
  private edadPipe = inject(EdadPipe);
  private estadosPipe = inject(RequestEstadosPipe);
  private treatmentNamePipe = inject(TreatmentNamePipe);
  private fechaPipe = inject(FechaPipe);
  private treatmentColorPipe = inject(TreatmentColorPipe);
  private _tagPipe = inject(TagPipe);

  endpoint: string = 'forms';
  endpointMock: string = 'assets/demo/data/requests.json';

  #state = signal<State>({
    loading: true,
    requests: []
  })

  searchItem = signal<string>('');
  status: any = [];

  public requests = computed(() => this.#state().requests);
  public loading = computed(() => this.#state().loading);


  params: HttpParams = new HttpParams();

  filters = signal<Filters | undefined>(
    {
      id: '',
      dni: '',
      status: '',
      page: '0',
      size: '10',
      user_name: '',
      doctor: '',
    }
  );

  setState(requests: any){
    this.#state.set({
      loading: false,
      requests
    })
  }

  clearFilters(){
    this.filters.set(undefined)
  }
  resetFilters(){
    this.filters.set(
      {
        id: '',
        dni: '',
        status: '',
        page: '0',
        size: '10',
        user_name: '',
        doctor: '',
      }
    )
  }

  getRequestsMock(): Observable<IRequests>{
    return this.http.get<IRequests>('assets/data/mocks/request.mock.json', { }).pipe(
      map((res: any) => {
        const { result, header, section, tabs } = res;
        const table = [];
        const response = {
          result: table,
          header: header,
          section: section,
          tabs: tabs
        };
        result.forEach((results) => {
          const { sections, id, status, created, doctor, treatment, unreadByDoctor, unreadModifiedDate } = results;
          const row = {
            id,
            created: this.fechaPipe.transform(created),
            name: "",
            last_name: "",
            entireName: "",
            age: "",
            documentNumber: "",
            documentImg: "",
            credentialImg: "",
            medical_secure: "",
            brand: "",
            doctor: doctor || {
              entireName: '-'
            },
            status: this.estadosPipe.transform(status).toString(),
            treatment: treatment,
            treatmentMod: this.treatmentNamePipe.transform(treatment).toString(),
            badgetClass: this.treatmentColorPipe.transform(treatment),
            unreadByDoctor: unreadByDoctor,
            unreadModifiedDate: unreadModifiedDate
          };

          sections.forEach((section: any) => {
            const { answers } = section;
            answers.forEach((answer) => {
              const { key, values } = answer;
              switch (key) {
                case 'name':
                  row.name = values;
                  break;
                case 'docnumber':
                  row.documentNumber = values;
                  break;
                case 'dni_resource':
                  row.documentImg = values;
                  break;
                case 'credential_resource':
                  row.credentialImg = values;
                  break;
                case 'last_name':
                  row.last_name = values;
                  row.entireName = `${row.name} ${row.last_name}`;
                  break;
                case 'birthday':
                  row.age = values ? this.edadPipe.transform(values).toString() : '';
                  break;
                case 'insurance_name':
                  row.medical_secure = values?.name ? values.name : values;
                  break;
                default:
                  break;
              }
            });
          });
          table.push(row);
        })
        return response;
      })
    )
  }

  getTags(){
    /* MOCKED */
    return this.http.get('assets/data/mocks/requestTags.mock.json').pipe(
      map((res: any) => {
        res.tags.map(s => {
          return s.label = this._tagPipe.transform(s.status);
        })
        this.status = res.tags;
        return res.tags
      })
    )

    // const endpoint = `forms/status`;
    // return this.http.get(`${environment.apiUrl}/${endpoint}`).pipe(
    //   map((res: any) => {
    //     console.log('Tags', res)
    //     res.map(s => {
    //       return s.label = this._tagPipe.transform(s.status);
    //     })
    //     this.status = res;
    //     return res
    //   })
    // )
  }

  getRequests(): Observable<IRequests>{
    this.endpoint = `forms`;
    let params = new HttpParams({ fromObject: Object.assign(this.filters()) });
    return this.http.get<IRequests>(`${environment.apiUrl}/${this.endpoint}`, { params }).pipe(
        map((res: any) => {
          const { result, header, section, tabs } = res;
          const table = [];
          const response = {
            result: table,
            header: header,
            section: section,
            tabs: tabs
          };
          result.forEach((results) => {
            const { sections, id, status, created, doctor, treatment, unreadByDoctor, unreadModifiedDate } = results;
            const row = {
              id,
              created: this.fechaPipe.transform(created),
              name: "",
              last_name: "",
              entireName: "",
              age: "",
              documentNumber: "",
              documentImg: "",
              credentialImg: "",
              medical_secure: "",
              brand: "",
              doctor: doctor || {
                entireName: '-'
              },
              status: this.estadosPipe.transform(status).toString(),
              treatment: treatment,
              treatmentMod: this.treatmentNamePipe.transform(treatment).toString(),
              badgetClass: this.treatmentColorPipe.transform(treatment),
              unreadByDoctor,
              unreadModifiedDate
            };

            sections.forEach((section: any) => {
              const { answers } = section;
              answers.forEach((answer) => {
                const { key, values } = answer;
                switch (key) {
                  case 'name':
                    row.name = values;
                    break;
                  case 'docnumber':
                    row.documentNumber = values;
                    break;
                  case 'dni_resource':
                    row.documentImg = values;
                    break;
                  case 'credential_resource':
                    row.credentialImg = values;
                    break;
                  case 'last_name':
                    row.last_name = values;
                    row.entireName = `${row.name} ${row.last_name}`;
                    break;
                  case 'birthday':
                    row.age = values ? this.edadPipe.transform(values).toString() : '';
                    break;
                  case 'insurance_name':
                    row.medical_secure = values?.name ? values.name : values;
                    break;
                  default:
                    break;
                }
              });
            });
            table.push(row);
          })
          console.log('response', response)
          return response;
        }));
  }

  getRequestPaged({ status, page, size = 0 }): Observable<IRequests>{
    return this.http.get<IRequests>(`${environment.apiUrl}/forms?status=${status ? status : ''}&page=${page}&size=${size}`)
      .pipe(
        map((res: any) => {
          const { result, header, section } = res;
          const table = [];
          const response = {
            result: table,
            header: header,
            section: section
          };
          result.forEach((results) => {
            const { sections, id, status, created, doctor, treatment } = results;
            const row = {
              id,
              created: this.fechaPipe.transform(created),
              name: "",
              last_name: "",
              entireName: "",
              age: "",
              documentNumber: "",
              documentImg: "",
              credentialImg: "",
              medical_secure: "",
              brand: "",
              doctor,
              status: this.estadosPipe.transform(status).toString(),
              treatment
            };

            sections.forEach((section: any) => {
              const { answers } = section;
              answers.forEach((answer) => {
                const { key, values } = answer;
                switch (key) {
                  case 'name':
                    row.name = values;
                    break;
                  case 'docnumber':
                    row.documentNumber = values;
                    break;
                  case 'dni_resource':
                    row.documentImg = values;
                    break;
                  case 'credential_resource':
                    row.credentialImg = values;
                    break;
                  case 'last_name':
                    row.last_name = values;
                    row.entireName = `${row.name} ${row.last_name}`;
                    break;
                  case 'birthday':
                    row.age = values ? this.edadPipe.transform(values).toString() : '';
                    break;
                  case 'insurance_name':
                    row.medical_secure = values?.name ? values.name : values;
                    break;
                  default:
                    break;
                }
              });
            });
            table.push(row);
          })
          return response;
        }));
  }

  getRequest(id: number): Observable<IRequests> {
    return this.http.get(`${environment.apiUrl}/forms/${id}`)
    //.pipe(mergeMap((res: any) => {
    .pipe(map((res: any) => {
        const { sections, comment } = res;
        sections.forEach((section: any) => {
            const { answers } = section;
            answers.forEach((answer) => {
                const { key, values } = answer;
                switch (key) {
                    case 'name':
                        res.name = values;
                        break;
                    case 'docnumber':
                      res.documentNumber = values;
                      break;
                    case 'dni_resource':
                      res.documentImg = values;
                      break;
                    case 'credential_resource':
                      res.credentialImg = values;
                      break;
                    case 'last_name':
                        res.last_name = values;
                        break;
                    case 'birthday':
                        res.age = values;
                        break;
                    case 'insurance_name':
                        res.medical_secure = values?.name ? values.name : values;
                        break;
                    default:
                        break;
                }
            });
        });
        return res;
    })
    );
  }

  updateMethodMedicine(idRequest: number, values: any){
    this.endpoint = `forms/${idRequest}/updateMedicine`;
		return this.http.put(`${environment.apiUrl}/${this.endpoint}`, values)
		.pipe(
			map((response: any) => {
				return response;
			})
		)
  }


  updateStateRequest(idRequest: number, values: any){
    this.endpoint = `forms/${idRequest}/updateStatus`;
		return this.http.put(`${environment.apiUrl}/${this.endpoint}`, values)
		.pipe(
			map((response: any) => {
				return response;
			})
		)
  }

  updateMedicRequest(idRequest: number, values: any){
    // this.endpoint = `forms/${idRequest}`;
    this.endpoint = `forms/${idRequest}/updateDoctor`;
		return this.http.put(`${environment.apiUrl}/${this.endpoint}`, values)
		.pipe(
			map((response: any) => {
				return response;
			})
		)
  }

  getComboById(idCombo){
    this.endpoint = `combos/${idCombo}`;
    return this.http.get(`${environment.apiUrl}/${this.endpoint}`).pipe(
      map((res: any) => {
        return res;
      })
    )
  }

}

